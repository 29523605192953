import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/common/header/Header";
import { Breadcrumbs, Button, Grid, Link, Skeleton, Stack } from "@mui/material";
import ClientSideMenu from "./ClientSideMenu";
import theme from "../../theme";
import {
  createOrder,
  deleteBasketProducts,
  getBasketProducts,
  getVoucher,
} from "../../services/core-api";
import { Basket } from "../../interfaces/core/Basket";
import { BasketItem } from "../../interfaces/core/BasketItem";
import {
  setCartItems,
  updateCartItemQuantity,
} from "../../store/reducers/basket";
import { RootState } from "../../store/store";
import Alert from "@mui/material/Alert";
import { Voucher } from "../../interfaces/core/Voucher";
import Footer from "../../components/common/Footer/Footer";
import { useSnackbar } from "../../components/common/SnackbarContext";
const defaultImage = "./no-image.png";

interface Data {
  id: number;
  name: string;
  image: string;
  manufacturer: string;
  description: string;
  carbs: number;
  quantity: number;
  protein: number;
  total: number;
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Article Number",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "manufacturer",
    numeric: false,
    disablePadding: false,
    label: "Manufacturer",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descriptions",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            sx={{
              pb: "4px",
              color: theme.palette.secondary.main,
              "&.Mui-checked": {
                color: theme.palette.secondary.main,
              },
              "&.MuiCheckbox-indeterminate": {
                color: theme.palette.secondary.main,
              },
            }}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={{
              fontSize: "15px",
              fontFamily: "Inter",
              fontWeight: 600,
              pb: "4px",
              pl: index === 0 ? 0 : "inherit",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  selectedItems: any;
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { selectedItems, numSelected } = props;

  const deleteProducts = () => {
    if (selectedItems) {
      (async () => {
        try {
          const user = localStorage.getItem("user");
          if (user) {
            const parsedUser = JSON.parse(user);
            const user_id = parsedUser.id;
            await deleteBasketProducts(user_id, selectedItems);
            window.location.reload();
          }
        } catch (error) {
        }
      })();
    }
  };

  return (
    <Toolbar
      sx={{
        minHeight: "40px !important",
        pl: { sm: 0.5 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <Tooltip title="Delete">
            <IconButton onClick={() => deleteProducts()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{ fontSize: "13px", fontFamily: "Inter" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            Delete {numSelected} items selected
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Toolbar>
  );
}

const BasketPage = () => {
  const frontendURL = process.env.REACT_APP_FRONTEND_URL;
  const [basket, setBasket] = React.useState<Basket | null>(null);
  const basketItems = useSelector((state: RootState) => state.cart.items);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("description");
  const [articlesAvailable, setArticlesAvailable] = React.useState(true);
  const [voucher, setVoucher] = React.useState<Voucher | null>(null);
  const [isloadingStock, setIsLoadingStock] = React.useState(true);
  const [voucherError, setVoucherError] = React.useState("");
  const [validVoucher, setValidVoucher] = React.useState(false);
  const [voucherChecked, setVoucherChecked] = React.useState(false);
  const [error, setError] = React.useState("");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const [totalPriceWithoutDiscount, setTotalPriceWithoutDiscount] =
    React.useState<number>(0);
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(false);
  const [deliveryMethod, setDeliveryMethod] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [disableVoucher, setDisableVoucher] = React.useState(false);
  const [discount, setDiscount] = React.useState<number>();
  const [discountPercentage, setDiscountPercentage] = React.useState<number>();
  const [comment, setComment] = React.useState("");
  const dispatch = useDispatch();
  const { showSnackbar } = useSnackbar();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = basketItems.map((n) => n.productId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  React.useEffect(() => {
    let activeBasket = true;
    if (activeBasket) {
      (async () => {
        try {
          const response = await getBasketProducts();
          if (response) {
            setArticlesAvailable(true);
            setBasket(response);
            dispatch(setCartItems(response.items));
            checkAvailability(response.items);
            setIsLoadingStock(false);
          }
        } catch (error) {
        }
      })();
    }
  }, [dispatch]);

  // Function to check if any basket item has `available: false`
  const checkAvailability = (basketItems: BasketItem[]) => {
    const hasUnavailableItems = basketItems.some(item => !item.available);
    setIsSubmitDisabled(hasUnavailableItems);
  };

  React.useEffect(() => {
    let calculatedTotalPrice = basketItems
      .reduce((sum, item) => {
        const price = item.totalPrice ? Number(item.totalPrice) : 0;
        return sum + price;
      }, 0)
      .toFixed(2);
  
    setTotalPriceWithoutDiscount(Number(calculatedTotalPrice));
  
    if (validVoucher && voucher) {
      calculateDiscount();
    } else {
      setTotalPrice(Number(calculatedTotalPrice)); 
    } 
  }, [basketItems, discount, validVoucher, voucher]);

  const handleQuantityChange = (product: BasketItem, event: any) => {
    const newQuantity = Number(event.target.value);
    dispatch(
      updateCartItemQuantity({
        productId: product.productId,
        quantity: newQuantity,
      })
    );
    
    // Recalculate discount when quantity changes
    if (validVoucher && voucher) {
      calculateDiscount();
    } else {
      // Update total price without discount
      let calculatedTotalPrice = basketItems
        .reduce((sum, item) => {
          const price = item.totalPrice ? Number(item.totalPrice) : 0;
          return sum + price;
        }, 0)
        .toFixed(2);
      
      setTotalPrice(Number(calculatedTotalPrice)); 
    }
  };
  
  // Extracted calculateDiscount function
  const calculateDiscount = () => {
    let calculatedTotalPrice = basketItems
      .reduce((sum, item) => {
        const price = item.totalPrice ? Number(item.totalPrice) : 0;
        return sum + price;
      }, 0)
      .toFixed(2);
  
    const discountPercentage = voucher?.discount_percentage || 0;
    const discountAmount = (Number(calculatedTotalPrice) * discountPercentage) / 100;
    const discountTotalPrice = Number(calculatedTotalPrice) - discountAmount;
  
    setDiscount(discountAmount);
    setTotalPrice(discountTotalPrice);
  };

  const handleVoucher = (event: any) => {
    setTimeout(() => {
      const voucherCode = event.target.value;
      if (voucherCode) {
        (async () => {
          try {
            const response = await getVoucher(voucherCode);
            if (response) {
              setVoucherChecked(true);
              setValidVoucher(true);
              setVoucher(response);
              setVoucherError("");
            }
          } catch (error) {
            setValidVoucher(false);
            setVoucherChecked(true);
            setVoucherError("Invalid voucher code");
          }
        })();
      }
    }, 600);
  };

  const handleApplyVoucher = () => {
    if (validVoucher && voucher) {

      let calculatedTotalPrice = basketItems
      .reduce((sum, item) => {
        const price = item.totalPrice ? Number(item.totalPrice) : 0;
        return sum + price;
      }, 0)
      .toFixed(2);

      setDisableVoucher(true);
      const discountPercentage = voucher.discount_percentage || 0;
  
      // Calculate the discount based on the current total price without discount
      const discountAmount = (Number(calculatedTotalPrice) * discountPercentage) / 100;
  
      // Calculate the new total price with the discount applied
      const discountTotalPrice = Number(calculatedTotalPrice) - discountAmount;
  
      // Update the state with the discount and new total prices
      setDiscountPercentage(voucher.discount_percentage);
      setDiscount(discountAmount);
      // Update with discounted price
      setTotalPrice(discountTotalPrice); 
    }
  };

  const handleDeliveryMethod = async (event: any, value: any) => {
    if (value) {
      setDeliveryMethod(value.label);
    }
  };

  const handlePaymentMethod = async (event: any, value: any) => {
    if (value) {
      setPaymentMethod(value.label);
    }
  };

  const handleComment = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const comment = event.target.value;
    if (comment) {
      setComment(comment);
    }
  };

  const handleSubmit = () => {
    const order = {
      user_id: 1,
      created_at: new Date().toISOString(), 
      updated_at: new Date().toISOString(),
      status: 'pending',
      discount: discount || 0,
      total: parseFloat(totalPrice.toFixed(2)), 
      comment: comment || "",
      delivery_method: deliveryMethod || "", 
      payment: paymentMethod || "", 
      visited: false, 
      items: basketItems.map((item: any) => ({
          productId: item.productId || 0,
          articleNumber: item.articleNumber || "", 
          internalNumber: item.internalNumber || "",
          dataSupplierId: item.dataSupplierId || 0, 
          mfrName: item.mfrName || "",
          image: item.image || "", 
          description: item.description || "",
          quantity: item.quantity || 1, 
          price: Number(item.price) || 0,
          totalPrice: Number(item.totalPrice) || 0 
      }))
    };
  
    (async () => {
      try {
        await createOrder(order);
        setTimeout(() => {
          showSnackbar('Order is created successfully!', 'success');
          window.location.href = `${frontendURL}`;
        }, 1000);
      } catch(error){
        showSnackbar('Order was not created!', 'error');
      }
    })();
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
          minHeight: '70vh'
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ClientSideMenu />
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box
              sx={{
                bgcolor: "#f1f1f1",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: 1,
                borderTop: `2px solid ${theme.palette.primary.dark}`,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "13px", pb: "4px" }}
                  >
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          sx={{ color: "black" }}
                          fontSize="small"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          ml: 1,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >   
                        Home
                      </Link>
                      ,
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Basket
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
                <TableContainer>
                {isloadingStock ? (
                    <Table>
                    <TableBody>
                      {[...Array(5)].map((_, index) => (
                        <TableRow key={index}>
                          {/* Skeleton placeholders for each column */}
                          <TableCell padding="checkbox">
                            <Skeleton variant="rectangular" width={20} height={20} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={100} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="rectangular" width={80} height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={80} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={120} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width={50} />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton variant="rectangular" width={40} height={40} />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton variant="rectangular" width={40} height={40} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  ):(
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={basket ? basket.total_items : 0}
                    />
                    <TableBody>
                      {basketItems.map((row, index) => {
                        const isItemSelected = isSelected(row.productId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const productTotalPrice = Number(row.totalPrice);
                        const product_total_price = productTotalPrice.toFixed(2)

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.productId}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.productId)
                                }
                                checked={isItemSelected}
                                sx={{
                                  color: theme.palette.secondary.main,
                                  "&.Mui-checked": {
                                    color: theme.palette.secondary.main,
                                  },
                                }}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    textAlign: "left",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    fontWeight: 500,
                                  }}
                                >
                                  {row.articleNumber}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{ width: "80px", marginRight: "10px" }}
                                  src={row.image || defaultImage}
                                  alt="Product"
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                textAlign: "left",
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 400,
                              }}
                            >
                              {row.mfrName}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                textAlign: "left",
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 400,
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row.description,
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                disabled={!row.available}
                                id="standard-number"
                                size="medium"
                                variant="standard"
                                value={row.quantity}
                                onChange={(e) => handleQuantityChange(row, e)}
                                sx={{
                                  width: "50px",
                                  background: "#fff",
                                  marginLeft: 2,
                                  marginRight: 2,
                                  "& .MuiInput-underline:before": {
                                    borderBottom: "none", // Remove the bottom border before hover
                                  },
                                  "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                                    {
                                      borderBottom: "none", // Remove the bottom border on hover
                                    },
                                  "& .MuiInput-underline:after": {
                                    borderBottom: "none", // Remove the bottom border after clicking
                                  },
                                }}
                                InputProps={{
                                  sx: {
                                    textAlign: "center",
                                    border: "1px solid #d4d4d4",
                                    height: "36px",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                    paddingLeft: ".9rem",
                                    width: "100%",
                                    "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                                      {
                                        borderBottom: "none", // Remove the bottom border on hover
                                      },
                                    "& .MuiInput-underline:after": {
                                      borderBottom: "none", // Remove the bottom border after clicking
                                    },
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                startIcon={
                                  <img
                                    style={{ width: "27px", height: "27px" }}
                                    src="./available_status.svg"
                                    alt="product status icon"
                                  />
                                }
                                sx={{
                                  padding: "4px 2px !important",
                                  // background: '#F33',
                                  background: row.available ? "#0C0" : "#F33",
                                  "& .css-1d6wzja-MuiButton-startIcon": {
                                    marginRight: "0px !important",
                                    marginLeft: "0px !important",
                                  },
                                  borderRadius: 0,
                                  ":hover": {
                                    // background: '#F33',
                                    background: row.available ? "#0C0" : "#F33",
                                  },
                                }}
                              ></Button>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 400,
                              }}
                            >
                              {row.price ? Number(row.price).toFixed(2) : ''} EUR
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 400,
                                minWidth: '110px'
                              }}
                            >
                              {product_total_price} EUR
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                   )}
                </TableContainer>
                {!articlesAvailable && (
                  <Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
                    <Alert severity="error">{error}</Alert>
                  </Stack>
                )}
                <EnhancedTableToolbar
                  selectedItems={selected}
                  numSelected={selected.length}
                />
              </Paper>
            </Box>
            <Box sx={{ border: "5px solid #f1f1f1", p: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Wholesale price sum inc. VAT
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontFamily: "Inter",
                        color: theme.palette.secondary.main,
                        fontWeight: 600,
                      }}
                    >
                      {totalPriceWithoutDiscount.toFixed(2)} EUR
                    </Typography>
                  </Box>
                </Grid>
                {discount && (
                  <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between", // Ensures text on the left and price on the right
                        alignItems: "center",
                        backgroundColor: "#e6f4ea", // Success background color
                        padding: "8px 16px",
                        borderRadius: "4px", // Rounded corners similar to Alert
                        border: "1px solid #b4dfc4", // Success border color
                        color: "#2e7d32", // Success text color
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      >
                        Discount {discountPercentage}%
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                        }}
                      >
                        - {discount.toFixed(2)} EUR
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontFamily: "Inter",
                        fontWeight: 500,
                      }}
                    >
                      Wholesale price sum inc. VAT and Dicsount
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontFamily: "Inter",
                        color: theme.palette.secondary.main,
                        fontWeight: 600,
                      }}
                    >
                      {totalPrice.toFixed(2)} EUR
                    </Typography>
                  </Box>
                </Grid>
                </>
                )}
                
                <Grid
                  item
                  xs={12}
                  sx={{ borderTop: `2px solid #f1f1f1` }}
                ></Grid>

                <Grid item xs={12} md={5}>
                  <Box>
                    <Autocomplete
                      disablePortal
                      id="delivery-method"
                      options={deliveryMethods}
                      sx={{
                        width: "100%",
                        height: "40px",
                        "& .MuiInputBase-root": {
                          height: "40px",
                          minHeight: "40px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          height: "40px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "40px",
                        },
                        "& .MuiInputBase-input": {
                          height: "40px",
                          padding: "0 14px",
                        },
                      }}
                      onChange={handleDeliveryMethod}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery method"
                          sx={{
                            height: "40px",
                          }}
                          InputLabelProps={{
                            sx: {
                              color: theme.palette.text.primary,
                              fontSize: "13px",
                              fontFamily: "Inter",
                              transform: "translate(14px, 0px) scale(1)",
                              "&.MuiInputLabel-shrink": {
                                transform: "translate(14px, -14px) scale(0.75)",
                              },
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              height: "40px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                              padding: "0 14px",
                              alignItems: "center",
                              boxSizing: "border-box",
                            },
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      id="payment-method"
                      options={paymentMethods}
                      sx={{
                        width: "100%",
                        height: "40px",
                        "& .MuiInputBase-root": {
                          height: "40px",
                          minHeight: "40px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          height: "40px",
                        },
                        "& .MuiInputLabel-root": {
                          lineHeight: "40px",
                        },
                        "& .MuiInputBase-input": {
                          height: "40px",
                          padding: "0 14px",
                        },
                      }}
                      onChange={handlePaymentMethod}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Payment method"
                          sx={{
                            mt: 3,
                            height: "40px",
                          }}
                          InputLabelProps={{
                            sx: {
                              color: theme.palette.text.primary,
                              fontSize: "13px",
                              fontFamily: "Inter",
                              transform: "translate(14px, 0px) scale(1)",
                              "&.MuiInputLabel-shrink": {
                                transform: "translate(14px, -14px) scale(0.75)",
                              },
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            sx: {
                              height: "40px",
                              fontSize: "13px",
                              fontFamily: "Inter",
                              padding: "0 14px",
                              alignItems: "center",
                              boxSizing: "border-box",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 6 }}>
                    <TextField
                      label="Voucher"
                      onChange={handleVoucher}
                      sx={{
                        mr: 3,
                        height: "40px",
                      }}
                      InputLabelProps={{
                        sx: {
                          color: theme.palette.text.primary,
                          fontSize: "13px",
                          fontFamily: "Inter",
                          transform: "translate(14px, 10px) scale(1)",
                          "&.MuiInputLabel-shrink": {
                            transform: "translate(14px, -10px) scale(0.75)",
                          },
                        },
                      }}
                      InputProps={{
                        sx: {
                          height: "40px",
                          fontSize: "13px",
                          fontFamily: "Inter",
                          padding: "0 14px",
                          alignItems: "center",
                          boxSizing: "border-box",
                        },
                      }}
                    />
                    <Button
                      sx={{
                        color: theme.palette.text.primary,
                        border: "1px solid #f1f1f1",
                        fontSize: "13px",
                        fontFamily: "Inter",
                        textTransform: "capitalize",
                      }}
                      disabled={disableVoucher}
                      onClick={handleApplyVoucher}
                    >
                      Apply
                    </Button>
                  </Box>
                  <Box sx={{ width: "100%", mt: 2 }}>
                    {voucherChecked &&
                      (validVoucher ? (
                        <Alert severity="success">Voucher is valid!</Alert>
                      ) : (
                        <Alert severity="error">{voucherError}</Alert>
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    onChange={handleComment}
                  />
                  <Button
                  disabled={!deliveryMethod || !paymentMethod || isSubmitDisabled}
                    sx={{
                      bgcolor: theme.palette.primary.dark,
                      color: "#fff",
                      width: "100%",
                      mt: 1,
                      border: "1px solid #f1f1f1",
                      fontSize: "13px",
                      fontFamily: "Inter",
                      textTransform: "capitalize",
                      ":hover": {
                        bgcolor: theme.palette.primary.dark,
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    Order Now
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

const deliveryMethods = [
  { label: "Pick up", year: 1 },
  { label: "Delivery", year: 2 },
];

const paymentMethods = [
  { label: "Cash", year: 1 },
  { label: "With Agreement", year: 2 },
  { label: "Bank Transfer", year: 3 },
];

export default BasketPage;
